import React, { useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import queryString from 'query-string';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Layout from '../components/layout/layout';
import { logout, setCenterAdminToken } from '../utils/auth';
import SEO from '../components/layout/seo';
import Panel from '../components/common/panel';
import { postApiRequest } from '../utils/request';

const CenterAdminDashboardSignIn = () => {
  useEffect(() => {
    logout();
  }, []);


  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      // Attempt to sign in with the password
      try {
        const result = await postApiRequest('/center-admin-sign-in', values);
        const { status } = result;
        if (status === 'ok') {
          setCenterAdminToken(values.password);
          const parsed = window.location.search ? queryString.parse(window.location.search) : {};
          const redir = parsed && parsed.redir ? parsed.redir : '/app/center-admin/dashboard';
          navigate(redir);
          return;
        }
        actions.setFieldError('password', 'Invalid password.');
      } catch (err) {
        console.log(err, 'should never happen');
      }
    },
  });

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasEmailError = formik.touched.email && formik.errors.email;
  const hasPasswordError = formik.touched.password && formik.errors.password;

  return (

    <Layout classes="bg-gray-50">
      <SEO title="Center admin sign in" />
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <Panel>
            <div className="py-6 px-4">
              <div>
                <Link to="/">
                  <img
                    className="mx-auto h-24 w-auto"
                    src="/fbl-logo.png"
                    alt="Fun Bowling Leagues Logo"
                  />
                </Link>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your bowling center account</h2>
                {/* <p className="mt-2 text-center text-sm text-gray-600">
                  Or{' '}
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    start your 14-day free trial
                  </a>
                </p> */}
              </div>
              <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      {...formik.getFieldProps('password')}
                      id="password"
                      name="password"
                      type="password"
                      className={`${hasPasswordError ? errorInputClasses : regularInputClasses} appearance-none relative block w-full px-3 py-2 border rounded-md focus:outline-none focus:z-10 sm:text-sm`}
                      placeholder="Bowling center password"
                    />
                    {hasEmailError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.email}
                      </p>
                    )}
                    {hasPasswordError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.password}
                      </p>
                    )}
                  </div>
                </div>


                <div>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                    </span>
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </Panel>
        </div>
      </div>
    </Layout>
  );
};

export default CenterAdminDashboardSignIn;
